body {
    font-family: Arial, Helvetica, sans-serif;
  }
  html, body {
    height: 99%;
  }
  table, td, th {
    margin: 5px 0;
    border: 1px solid #aaa;
  }
  table {
    border-collapse: collapse;
  }
  td, th {
    padding: 10px;
  }
  progress {
    width: 40%;
  }
  span {
    font-size: small;
    font-style: italic;
  }
  button {
    margin: 0 5px;
  }
  input[type=number] {
    width: 75px;
  }
  input[type=color] {
    margin: 0 5px;
    width: 25px;
    height: 25px;
  }
  select {
    width: 90px;
  }
  input[type=range] {
    width: 100px;
    margin: 0px 10px;
  }
  #fileinput {
    width: 50%;
    border: 1px dotted gray;
  }
  #about {
    font-size: small;
    font-style: italic;
  }
  #dwv {
    display: flex;
    min-height: 200px;
    height: 40%;
    /* compensate for layer absolute position */
    margin-bottom: 10px;
  }
  .layerGroup {
    flex: 1 1;
    margin: 5px 5px 5px 0;
    background-color: limeGreen;
    /* allow child centering */
    position: relative;
  }
  .layer {
    /* needed for overlay */
    position: absolute;
    /* center */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  canvas {
    /* avoid parent auto-resize */
    vertical-align: middle;
  }
  .line {
    padding: 5px;
    background-color: #E8E8E8;
  }
  /** tooltip */
  .layerGroup span {
    display: none;
    background-color: palegreen;
    padding: 2px;
  }
  .layerGroup:hover span {
    display: inline-block;
    position: absolute;
    overflow: hidden;
  }
  /** crossshair */
  .layerGroup hr {
    pointer-events: none;
    border: none;
    position: absolute;
    margin: 0;
  }
  hr.horizontal {
    border-top: 2px dashed lime;
  }
  hr.vertical {
    border-top: 2px dashed lime;
    transform-origin: left;
    transform: rotate(90deg);
  }
  .toolFeatures {
    margin: 0 5px;
  }